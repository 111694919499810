import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { MasterCardCta } from 'components';
import { useContext, useState } from 'react';
import { SubmitHandler, useForm, useFormState } from 'react-hook-form';
import { Container, Form, FormfieldGroup, FormfieldString, FormfieldTypeheadDawa, Grid, GridCell } from 'shared';
import { DictionaryContext } from '../../../../context/Dictionary';
import { _Actions, _MasterCard, _Placeholder, _User } from '../../../../dictionaryItems';
import yup from '../../../../utilities/validations';
import styles from './MasterCardUserForm.module.scss';

type coInsurer = {
	name: string;
	birthDate: string;
};

type Address = {
	roadName: string;
	roadNumber: string;
	postalCode: string;
	city: string;
};

export interface MasterCardUserFormData {
	firstName: string;
	lastName: string;
	dawaAddressString: string;
	address: Address;
	email: string;
	birthDate: string;
	coInsurers: coInsurer[];
	[key: string]: string | number | coInsurer[] | Address;
}

export interface MasterCardUserFormProps {
	callbackContinue: (data: MasterCardUserFormData) => void;
	callbackPrevious: () => void;
	className?: string;
	insuredCount: number;
	userFormData?: MasterCardUserFormData;
	cardTypeGroup: MasterCardTypeGroup;
}

export const MasterCardUserForm: React.FC<MasterCardUserFormProps> = ({
	callbackContinue,
	callbackPrevious,
	className,
	insuredCount,
	userFormData,
	cardTypeGroup,
}) => {
	const schema = yup.object({
		firstName: yup.string().required('Angiv fornavn'),
		lastName: yup.string().required('Angiv efternavn'),
		dawaAddressString: yup.string().required('Angiv adresse').validDawaAddress('Angiv en gyldig adresse'),
		email: yup.string().email('Angiv e-mailadresse').required('Angiv en gylidg e-mailadresse'),
		birthDate: yup.string().required('Angive ett fødselsdato'),
	});
	const dictionary = useContext(DictionaryContext);
	const { register, handleSubmit, control } = useForm<MasterCardUserFormData>({ resolver: yupResolver(schema) });
	const [additionalAddressValues, setAdditionalAddressValues] = useState<Address>();
	const [errorSummary, setErrorSummary] = useState(0);

	const getAdditionalAddressValues = (data: Address) => {
		setAdditionalAddressValues(data);
	};

	const onSubmit: SubmitHandler<MasterCardUserFormData> = (data) => {
		const coInsuredFields = Object.keys(data).filter((key) => key.includes('coInsured'));
		const coInsuredFieldsLength = coInsuredFields.length / 2;

		const coInsurers: coInsurer[] = [];

		for (let i = 0; i < coInsuredFieldsLength; i++) {
			coInsurers.push({
				name: data[`coInsuredName${i}`].toString(),
				birthDate: data[`coInsuredBirthDate${i}`].toString(),
			});
		}
		data.address = {
			city: additionalAddressValues?.city,
			postalCode: additionalAddressValues?.postalCode,
			roadName: additionalAddressValues?.roadName,
			roadNumber: additionalAddressValues?.roadNumber,
		};

		data.coInsurers = coInsurers;
		callbackContinue(data);
	};
	const { errors } = useFormState({
		control,
	});

	const onError = (data) => {
		const totalErrors = Object.keys(data).length;
		setErrorSummary(totalErrors);
	};

	return (
		<div className={classNames(styles.MasterCardUserForm, className)}>
			<Form onSubmit={handleSubmit(onSubmit, onError)} errorSummary={errorSummary}>
				<Container width="Medium">
					<FormfieldGroup
						className={styles.MasterCardUserForm_wrapper}
						id="userform"
						name="PolicyOwner"
						label={_MasterCard(dictionary, 'PolicyOwner')}
					>
						<Grid className={classNames(styles.MasterCardUserForm_grid, 'u-grid--cell-padding20')} wrap>
							<GridCell desktopWidth="50">
								<FormfieldString
									id="first-name"
									name="firstName"
									type="text"
									label={_User(dictionary, 'FirstName')}
									state={errors.firstName ? { hasError: true, required: true } : { required: true }}
									placeholder={_Placeholder(dictionary, 'EnterFirstName')}
									register={register}
									defaultValue={userFormData?.firstName}
									errorMessage={errors.firstName?.message}
								/>
							</GridCell>
							<GridCell desktopWidth="50">
								<FormfieldString
									id="last-name"
									name="lastName"
									type="text"
									label={_User(dictionary, 'LastName')}
									state={errors.lastName ? { hasError: true, required: true } : { required: true }}
									placeholder={_Placeholder(dictionary, 'EnterLastName')}
									register={register}
									defaultValue={userFormData?.lastName}
									errorMessage={errors.lastName?.message}
								/>
							</GridCell>

							<GridCell desktopWidth="100">
								<FormfieldTypeheadDawa
									id="dawaAddressString"
									name="dawaAddressString"
									label={_User(dictionary, 'Address')}
									placeholder={_Placeholder(dictionary, 'EnterAddress')}
									register={register}
									getAddress={getAdditionalAddressValues}
									state={
										errors.dawaAddressString
											? { hasError: true, required: true }
											: { required: true }
									}
									defaultValue={userFormData?.dawaAddressString}
									errorMessage={errors.dawaAddressString?.message}
								/>
							</GridCell>

							<GridCell desktopWidth="50">
								<FormfieldString
									id="email"
									name="email"
									type="email"
									label={_User(dictionary, 'Email')}
									state={errors.email ? { hasError: true, required: true } : { required: true }}
									placeholder={_Placeholder(dictionary, 'EnterYourEmail')}
									register={register}
									defaultValue={userFormData?.email}
									errorMessage={errors.email?.message}
								/>
							</GridCell>
							<GridCell desktopWidth="50">
								<FormfieldString
									id="birth-date"
									name="birthDate"
									type="date"
									label={_User(dictionary, 'BirthDate')}
									state={errors.birthDate ? { hasError: true, required: true } : { required: true }}
									placeholder={_Placeholder(dictionary, 'EnterYourBirthday')}
									register={register}
									defaultValue={userFormData?.birthDate}
									errorMessage={errors.birthDate?.message}
								/>
							</GridCell>
						</Grid>
					</FormfieldGroup>

					{insuredCount > 1 && (
						<FormfieldGroup
							className={styles.MasterCardUserForm_wrapper}
							id="co-insured"
							name="coInsured"
							label={_MasterCard(dictionary, 'CoInsured')}
							closeCtaText={_Actions(dictionary, 'Close')}
							helpCtaText={
								cardTypeGroup !== 'worldelite'
									? _MasterCard(dictionary, 'CoInsuredHelpText')
									: _MasterCard(dictionary, 'CoInsuredHelpTextWorldelite')
							}
						>
							{Array.from(Array(insuredCount - 1), (e, i) => {
								return (
									<Grid
										className={classNames(
											styles.MasterCardUserForm_grid,
											styles.MasterCardUserForm_grid___sibling,
											'u-grid--cell-padding20',
										)}
										wrap
										key={i + 'co-insured'}
									>
										<GridCell desktopWidth="50">
											<FormfieldString
												id={`co-insured-name-${i}`}
												name={`coInsuredName${i}`}
												type="text"
												label={_User(dictionary, 'Name')}
												register={register}
												state={
													errors[`coInsuredName${i}`]
														? { hasError: true, required: true }
														: { required: true }
												}
												defaultValue={
													userFormData?.[`coInsuredName${i}`]
														? String(userFormData[`coInsuredName${i}`])
														: null
												}
											/>
										</GridCell>
										<GridCell desktopWidth="50">
											<FormfieldString
												id={`co-insured-birth-date-${i}`}
												name={`coInsuredBirthDate${i}`}
												type="date"
												label={_User(dictionary, 'BirthDate')}
												register={register}
												state={
													errors[`coInsuredBirthDate${i}`]
														? { hasError: true, required: true }
														: { required: true }
												}
												defaultValue={
													userFormData?.[`coInsuredBirthDate${i}`]
														? String(userFormData[`coInsuredBirthDate${i}`])
														: null
												}
											/>
										</GridCell>
									</Grid>
								);
							})}
						</FormfieldGroup>
					)}

					<MasterCardCta
						className={styles.MasterCardUserForm_cta}
						callbackPrevious={callbackPrevious}
						continueText={_Actions(dictionary, 'Next')}
						submit
					/>
				</Container>
			</Form>
		</div>
	);
};
