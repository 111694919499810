import classNames from 'classnames';
import { useContext, useEffect } from 'react';
import { SubmitHandler, useForm, useFormState } from 'react-hook-form';
import {
	Button,
	Form,
	FormfieldCheckbox,
	FormfieldGroup,
	FormfieldRadio,
	FormfieldSelect,
	FormfieldString,
	Grid,
	GridCell,
	Heading,
	RichText,
} from 'shared';
import { DictionaryContext } from '../../../context/Dictionary';
import { _Actions, _Company, _Customer, _Errors, _Placeholder, _Time } from '../../../dictionaryItems';
import { formatDayDate } from '../../../utilities/formatDayDate';
import { OpeningHoursProps } from '../../../utilities/requestCallBack';
import styles from './CallBackForm.module.scss';

export interface CallBackFormProps {
	banksList: Option[];
	bankBranchesList: Option[];
	callSubjectList: Option[];
	descriptionText?: string;
	openingHours: OpeningHoursProps[];
	registerCallBack: (data) => void;
	handleSelectedDateIndex: (index: string) => void;
	handleSelectedBank: (bank: string) => void;
	selectedDate: OpeningHoursProps;
	title?: string;
	timeOptions: Option[];
}

export type Option = {
	selected?: boolean;
	text: string;
	value: string;
};

export interface CallBackFormData {
	bankAdvisorEmail: string;
	consent: boolean;
	customerNumber: string;
	name: string;
	phoneNumber: string;
	selectedDateIndex: string;
	selectedTimeIndex: string;
	selectedBank: string;
	selectedBankBranch: string;
	selectedCallSubject: string;
}

export const CallBackForm: React.FC<CallBackFormProps> = ({
	banksList,
	bankBranchesList,
	callSubjectList,
	descriptionText,
	openingHours,
	registerCallBack,
	handleSelectedDateIndex,
	handleSelectedBank,
	title,
	timeOptions,
}) => {
	const { control, handleSubmit, register, watch } = useForm<CallBackFormData>();

	const selectedDateObserver = watch('selectedDateIndex');
	const selectedBankObserver = watch('selectedBank');

	const dictionary = useContext(DictionaryContext);

	const { errors } = useFormState({
		control,
	});

	useEffect(() => {
		if (!selectedDateObserver) return;
		handleSelectedDateIndex(selectedDateObserver);
	}, [handleSelectedDateIndex, selectedDateObserver]);

	useEffect(() => {
		if (!selectedBankObserver) return;
		handleSelectedBank(selectedBankObserver);
	}, [handleSelectedBank, selectedBankObserver]);

	const onSubmit: SubmitHandler<CallBackFormData> = (data) => {
		data.phoneNumber = `+45${data.phoneNumber}`;
		registerCallBack(data);
	};

	return (
		<div className={classNames(styles.CallBackForm, 'u-container--slim')}>
			{title && (
				<Heading className={styles.CallBackForm_title} headingLevel={'h2'}>
					{title}
				</Heading>
			)}

			{descriptionText && <RichText className={styles.ArticleText} content={descriptionText} />}

			<Form onSubmit={handleSubmit(onSubmit)}>
				<Grid wrap={true} container={true} className="u-grid--cell-padding30-sm u-grid--cell-padding40-md ">
					<GridCell>
						<FormfieldString
							id="phoneNumber"
							name="phoneNumber"
							label={_Customer(dictionary, 'CustomerPhoneNumber')}
							type="text"
							register={register}
							placeholder={_Placeholder(dictionary, 'EnterPhoneNumber')}
							pattern="\d*"
							maxLength={8}
							minLength={8}
							state={errors.phoneNumber ? { hasError: true, required: true } : { required: true }}
						/>
					</GridCell>

					<GridCell>
						<FormfieldString
							id="name"
							name="name"
							label={_Customer(dictionary, 'CustomerName')}
							type="text"
							register={register}
							placeholder={_Placeholder(dictionary, 'EnterName')}
							state={errors.name ? { hasError: true, required: true } : { required: true }}
						/>
					</GridCell>

					<GridCell>
						<FormfieldCheckbox
							register={register}
							id="consent"
							name="consent"
							state={errors.consent ? { hasError: true, required: true } : { required: true }}
							multiline={true}
							label={
								_Customer(dictionary, 'CustomerConsentToBeCalled') +
								' ' +
								_Company(dictionary, 'CompanyName') +
								' ' +
								_Time(dictionary, 'ForTheSpecifiedTimePeriod')
							}
						/>
					</GridCell>

					{openingHours && (
						<GridCell>
							<FormfieldGroup
								name="selectedDateIndex"
								id="selectedDateIndex"
								label={_Time(dictionary, 'Day')}
								register={register}
								className={styles.CallBackForm_dateSelect}
								errorMessage={_Errors(dictionary, 'ChooseAtLeastOneField')}
								style="threeColumns"
							>
								{openingHours.map((date, index) => (
									<FormfieldRadio
										className={styles.CallBackForm_dateOption}
										style="button"
										name="selectedDateIndex"
										value={index.toString()}
										label={formatDayDate(
											date.open,
											_Time(dictionary, 'Today'),
											_Time(dictionary, 'Tomorrow'),
										)}
										key={index}
										id={index + ' selectedDate'}
										register={register}
										defaultChecked={index == 0 ? true : false}
									/>
								))}
							</FormfieldGroup>
						</GridCell>
					)}

					{timeOptions && (
						<GridCell>
							<FormfieldSelect
								id="selectedTimeIndex"
								name="selectedTimeIndex"
								label={_Time(dictionary, 'Time')}
								options={timeOptions}
								register={register}
								state={
									errors.selectedTimeIndex ? { hasError: true, required: true } : { required: true }
								}
								errorMessage={_Errors(dictionary, 'ChooseAtLeastOneField')}
							/>
						</GridCell>
					)}

					{banksList && (
						<GridCell>
							<FormfieldSelect
								id="selectedBank"
								name="selectedBank"
								label={_Customer(dictionary, 'CustomerBank')}
								options={[
									{
										text: _Actions(dictionary, 'ChooseBank'),
										value: '',
										selected: true,
										hidden: true,
									},
									...banksList,
								]}
								register={register}
								state={errors.selectedBank ? { hasError: true, required: true } : { required: true }}
							/>
						</GridCell>
					)}
					{bankBranchesList && (
						<GridCell>
							<FormfieldSelect
								id="selectedBankBranch"
								name="selectedBankBranch"
								label={_Customer(dictionary, 'CustomerLocalDepartment')}
								options={[
									{
										text: _Actions(dictionary, 'ChooseBankBranch'),
										value: '',
										selected: true,
										hidden: true,
									},
									...bankBranchesList,
								]}
								register={register}
								state={
									errors.selectedBankBranch ? { hasError: true, required: true } : { required: true }
								}
							/>
						</GridCell>
					)}

					{callSubjectList && (
						<GridCell>
							<FormfieldSelect
								id="selectedCallSubject"
								name="selectedCallSubject"
								label={_Customer(dictionary, 'CustomerCallSubject')}
								options={[
									{
										text: _Actions(dictionary, 'ReasonForCall'),
										value: '',
										selected: true,
										hidden: true,
									},
									...callSubjectList,
								]}
								register={register}
								state={
									errors.selectedCallSubject ? { hasError: true, required: true } : { required: true }
								}
							/>
						</GridCell>
					)}

					<GridCell>
						<FormfieldString
							id="bankAdvisorEmail"
							name="bankAdvisorEmail"
							label={_Customer(dictionary, 'BankAdvisorEmail')}
							type="email"
							register={register}
							placeholder={_Placeholder(dictionary, 'EnterEmail')}
							state={errors.bankAdvisorEmail ? { hasError: true, required: true } : { required: true }}
							pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
						/>
					</GridCell>

					<GridCell>
						<FormfieldString
							id="customerNumber"
							name="customerNumber"
							label={_Customer(dictionary, 'PSKCustomerNumber')}
							type="number"
							register={register}
							placeholder={_Placeholder(dictionary, 'EnterPSKCustomerNumber')}
							state={errors.customerNumber ? { hasError: true } : {}}
						/>
					</GridCell>

					<GridCell center={true} className={styles.CallBackForm_submitButton}>
						<Button style="primary" type="submit">
							{_Actions(dictionary, 'OrderCall')}
						</Button>
					</GridCell>
				</Grid>
			</Form>
		</div>
	);
};
