import styles from './MasterCardContent.module.scss';

export interface MasterCardContentProps {
	heading?: string;
	text?: string;
	additionalText?: string;
}

export const MasterCardContent: React.FC<MasterCardContentProps> = ({ heading, text, additionalText }) => {
	return (
		<div className={styles.MasterCardContent}>
			<p className={styles.MasterCardContent_heading}>{heading}</p>
			<p className={styles.MasterCardContent_text}>{text}</p>
			<p className={styles.MasterCardContent_additionalText}>{additionalText}</p>
		</div>
	);
};
