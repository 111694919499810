import { Component, Layout, Main } from 'components';
import { ComponentProps } from 'components/Component';
import { PageContext, PageProps } from 'context/PageContext';
import React, { useContext } from 'react';

export interface ContentPageProps extends PageProps {
	pageSections: ComponentProps[];
	pageHeader: ComponentProps[];
}

export const ContentPage: React.FC<ContentPageProps> = (props) => {
	const pageContext = useContext(PageContext);

	return (
		<Layout {...props}>
			<Main
				header={pageContext.pageHeaderContent.items.map((headerModule, index) => (
					<Component key={index} {...headerModule.content} />
				))}
			>
				{pageContext.pageSectionsContent.items.map((sectionModule, index) => (
					<Component key={index} {...sectionModule.content} />
				))}
			</Main>
			{/* {page?.footer && <Footer components={page.footer} />} */}
		</Layout>
	);
};
