import { MasterCardTravelFormData, MasterCardUserFormData } from 'components';
import { DictionaryContext } from '../../../context/Dictionary';
import { PageContext, PageProps } from '../../../context/PageContext';
// import { SiteContext } from 'Online/context/SiteContext';
import React, { useContext, useEffect, useState } from 'react';
import { FactboxItemProps } from 'shared';
import { toKebabCase } from 'utilities';
import { _MasterCard } from '../../../dictionaryItems';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
	getMasterCardConfig,
	getMasterCardPrice,
	selectMasterCardCalculationStatus,
	selectMasterCardConfig,
	selectMasterCardConfigStatus,
	selectMasterCardPaymentId,
	selectPriceSummary,
	selectTravelFormData,
	selectUserFormData,
	setPaymentId,
	setTravelFormData,
	setUserFormData,
} from '../../../store/slices/mastercardSlice';
import { getMasterCardPaymentUrl, notifyMasterCardUser } from '../../../utilities/mastercardQueries';
import { MasterCardPage } from './MasterCardPage';

export type MasterCardListType = 'travel' | 'user' | 'price';

export interface PaymentDetails {
	amount: number;
	cardName: string;
	date: Date;
	id: string;
	mailDidSend: boolean;
}
export interface MasterCardPageAPIProps extends PageProps {
	bannerImage: Image;
	conditionsHeading: string;
	conditionsText: string;
	description?: string;
	heading: string;
	image: Image;
	masterCardAttentionIcon: Image;
	masterCardCallIcon: Image;
	masterCardType: MasterCardType;
	paymentProviders: FactboxItemProps[];
	termsUrl: Link;
}

export const MasterCardPageAPI: React.FC<MasterCardPageAPIProps> = (props) => {
	const pageContext = useContext(PageContext);
	const { masterCardType, termsUrl, pageModel } = pageContext;

	const [currentStep, setCurrentStep] = useState(1);
	const [paymentError, setPaymentError] = useState(false);

	const dispatch = useAppDispatch();
	const dictionary = useContext(DictionaryContext);
	// const siteContext = useContext(SiteContext);
	// const utilityIcons: UtilityIcons = siteContext.getSettings('utilityIcons') ?? {};
	// const { errorIcon } = utilityIcons;
	const cardTypeGroup: MasterCardTypeGroup =
		masterCardType === 'MS' || masterCardType === 'MSB' || masterCardType === 'MSBN'
			? 'standard'
			: masterCardType === 'MG' || masterCardType === 'MGN' || masterCardType === 'MGB'
			? 'gold'
			: masterCardType === 'MP' ||
			  masterCardType === 'MPN' ||
			  masterCardType === 'MPB' ||
			  masterCardType === 'MPBN'
			? 'platinum'
			: masterCardType === 'WEM' || masterCardType === 'WEMN'
			? 'worldelite'
			: null;
	const [paymentDetails, setPaymentDetails] = useState<PaymentDetails>(null);
	const cardConfig = useAppSelector((state) => selectMasterCardConfig(state, masterCardType));

	useEffect(() => {
		async function fetchConfig() {
			dispatch(getMasterCardConfig(masterCardType));
		}
		!cardConfig && fetchConfig();
	}, [dispatch, cardConfig]);

	const handleFormSubmit = (
		formData: MasterCardTravelFormData | MasterCardUserFormData,
		type: MasterCardListType,
	) => {
		if (type === 'travel') dispatch(setTravelFormData(formData as MasterCardTravelFormData));
		if (type === 'user') dispatch(setUserFormData(formData as MasterCardUserFormData));
	};

	const userFormData = useAppSelector(selectUserFormData);
	const priceSummary = useAppSelector(selectPriceSummary);
	const travelFormData = useAppSelector(selectTravelFormData);
	const configStatus = useAppSelector(selectMasterCardConfigStatus);
	const calculationStatus = useAppSelector(selectMasterCardCalculationStatus);
	const status = {
		configStatus,
		calculationStatus,
	};
	const paymentId = useAppSelector(selectMasterCardPaymentId);

	useEffect(() => {
		if (travelFormData) {
			dispatch(
				getMasterCardPrice({
					travelFormData,
					masterCardType: masterCardType,
				}),
			);
		}
	}, [dispatch, travelFormData, masterCardType]);

	const handlePayment = async () => {
		const cancelUrl = window.location.href.split('?')[0] + '?s=' + toKebabCase(_MasterCard(dictionary, 'Overview'));
		const returnUrl = window.location.href.split('?')[0] + '?s=' + toKebabCase(_MasterCard(dictionary, 'Receipt'));
		const paymentConfig = await getMasterCardPaymentUrl(masterCardType, returnUrl, termsUrl?.url, cancelUrl);
		if ('paymentId' in paymentConfig) {
			dispatch(setPaymentId(paymentConfig.paymentId));
			if (paymentConfig?.hostedPaymentPageUrl != undefined) {
				window.location.href = paymentConfig.hostedPaymentPageUrl;
			}
		} else {
			setPaymentError(true);
		}
	};
	const handleEmail = async () => {
		const paymentDetails = await notifyMasterCardUser(masterCardType, paymentId);
		if ('id' in paymentDetails) {
			setPaymentDetails(paymentDetails);
		}
	};

	return (
		<MasterCardPage
			callbackPayment={handlePayment}
			callbackEmail={handleEmail}
			currentStep={currentStep}
			// errorIcon={errorIcon}
			setCurrentStep={setCurrentStep}
			formSubmit={handleFormSubmit}
			paymentDetails={paymentDetails}
			paymentError={paymentError}
			paymentId={paymentId}
			cardType={masterCardType}
			cardTypeGroup={cardTypeGroup}
			priceSummary={priceSummary}
			masterCardConfig={cardConfig && cardConfig}
			travelFormData={travelFormData}
			userFormData={userFormData}
			status={status}
			{...pageModel}
		/>
	);
};
