import classNames from 'classnames';
import { useContext } from 'react';
import { Button } from 'shared';
import { DictionaryContext } from '../../../../context/Dictionary';
import { _Actions } from '../../../../dictionaryItems';
import styles from './MasterCardCta.module.scss';

export interface MasterCardCtaProps {
	callbackContinue?: () => void;
	callbackPrevious?: () => void;
	continueDisabled?: boolean;
	continueText: string;
	className?: string;
	previous?: boolean;
	submit?: boolean;
}

export const MasterCardCta: React.FC<MasterCardCtaProps> = ({
	callbackContinue,
	callbackPrevious,
	continueDisabled,
	continueText,
	className,
	previous = true,
	submit,
}) => {
	const dictionary = useContext(DictionaryContext);
	return (
		<div className={classNames(styles.MasterCardCta, className)}>
			{previous && (
				<Button className={styles.MasterCardCta_button} onClick={callbackPrevious} style="secondary">
					{_Actions(dictionary, 'Back')}
				</Button>
			)}
			<Button
				className={styles.MasterCardCta_button}
				onClick={callbackContinue && callbackContinue}
				style="primary"
				type={submit ? 'submit' : 'button'}
				disabled={continueDisabled}
			>
				{continueText}
			</Button>
		</div>
	);
};
