import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { GetImageUrl, GetSrcSetString, imageSizesConfig, inView } from 'utilities';
import styles from './Image.module.scss';

export interface ImageProps extends Image {
	aspectRatio?: number;
	className?: string;
	isCenter?: boolean;
	isCircular?: boolean;
	isCover?: boolean;
	isFullWidth?: boolean;
	lazyload?: boolean;
	objectFitFallback?: boolean;
	sizes: string;
}

export const Image: React.FC<ImageProps> = ({
	aspectRatio,
	className,
	isCenter,
	isCircular,
	isCover = true,
	isFullWidth,
	lazyload = true,
	objectFitFallback = true,
	properties = {},
	sizes,
	url,
}) => {
	const { thumbnailSize, fallbackSize } = imageSizesConfig.images;
	const { altText, imageFocalPoint } = properties;

	const imageUrl = GetImageUrl({ url, width: thumbnailSize });

	const [src, setSrc] = useState(imageUrl);
	const [srcSet, setSrcSet] = useState(imageUrl);

	const [isFallback, setIsFallback] = useState(false);

	const imageRef = useRef(null);

	useEffect(() => {
		if (objectFitFallback) objectFit();
		if (lazyload) inView({ elm: imageRef.current, callback: reveal });
		if (!lazyload) reveal();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const reveal = () => {
		const srcSetString = GetSrcSetString({ url, aspectRatio, imageFocalPoint });
		setSrcSet(srcSetString);

		const srcString = GetImageUrl({ url, width: fallbackSize });
		setSrc(srcString);
	};

	const objectFit = () => {
		// If the useragent doesn't support ObjectFit (IE), we need to
		// add fallback image to the picture element
		const supportObjectFit = 'objectFit' in document.documentElement.style;

		if (!supportObjectFit) setIsFallback(true);
	};

	return (
		<span
			ref={imageRef}
			className={classNames(
				styles.Image,
				{ [styles.isFallback]: isFallback },
				{ [styles.Image___center]: isCenter },
				{ [styles.Image___circular]: isCircular },
				{ [styles.Image___cover]: isCover },
				{ [styles.Image___fullWidth]: isFullWidth },
				className,
			)}
			style={isFallback ? { backgroundImage: `url(${src})` } : {}}
		>
			{!isFallback && (
				<img className={styles.Image_asset} src={src} srcSet={srcSet} sizes={sizes} alt={altText} />
			)}
		</span>
	);
};
