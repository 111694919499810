import classNames from 'classnames';
import { RichText } from 'shared';
import styles from './FooterCard.module.scss';

export interface FooterCardProps {
	className?: string;
	links?: Link[];
	text?: string;
}

export const FooterCard: React.FC<FooterCardProps> = ({ className, links, text }) => {
	return (
		<div className={classNames(styles.FooterCard, className)}>
			<RichText className={styles.FooterCard_text} content={text} />

			{links && (
				<ul className={styles.FooterCard_links}>
					{links.map((link, index) => {
						return (
							<li key={index} className={styles.FooterCard_linkItem}>
								<a className={styles.FooterCard_link} href={link.url} target={link?.target}>
									{link.title}
								</a>
							</li>
						);
					})}
				</ul>
			)}
		</div>
	);
};
