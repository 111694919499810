import classNames from 'classnames';

export interface ThemeContext {
	themeName: string;
	children?: React.ReactNode;
}

export const ThemeContext: React.FC<ThemeContext> = (props) => {
	//Original supporting page themes: <div className={classNames(`${props?.themeName}-theme`, `${props?.themeName}-theme--${props?.documentType}`)}>
	return <div className={classNames(`${props?.themeName}`)}>{props?.children}</div>;
};
