import classNames from 'classnames';
import { Container, Heading, Image } from 'shared';
import styles from './MasterCardHeader.module.scss';

export interface MasterCardHeaderProps {
	className: string;
	heading: string;
	description?: string;
	masterCardImage: Image;
}

export const MasterCardHeader: React.FC<MasterCardHeaderProps> = ({
	className,
	heading,
	description,
	masterCardImage,
}) => {
	return (
		<div
			className={classNames(
				styles.MasterCardHeader,
				description && styles.MasterCardHeader___withDescription,
				className,
			)}
		>
			<Container width="Medium">
				<div className={styles.MasterCardHeader_wrapper}>
					<Heading className={styles.MasterCardHeader_heading} headingLevel="h2">
						{heading}
					</Heading>
					<div className={styles.MasterCardHeader_image}>
						<Image
							isCover={false}
							isFullWidth
							url={masterCardImage.url}
							properties={masterCardImage.properties}
							sizes="(max-width: 60rem) 7rem, 15rem"
						/>
					</div>
					{description && <p className={styles.MasterCardHeader_description}>{description}</p>}
				</div>
			</Container>
		</div>
	);
};
