export const getPartnerByCardType = (masterCardType: MasterCardType): Partner => {
	switch (masterCardType) {
		case 'MSBN':
		case 'MGN':
		case 'MPN':
		case 'MPBN':
		case 'WEMN':
			return 'Nykredit';

		default:
			return 'Lopi';
	}
};
