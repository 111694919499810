import classNames from 'classnames';
import masterCardCallIcon from 'public/icons/pictogram_telephone_info.svg';
import { useContext, useEffect, useState } from 'react';
import { AttentionCard, Container, DescriptionList, DescriptionListProps, PageLoader } from 'shared';
import { DictionaryContext } from '../../../../context/Dictionary';
import { _MasterCard, _Services, _Units } from '../../../../dictionaryItems';
import styles from './MasterCardReceipt.module.scss';

export interface MasterCardReceiptProps {
	className?: string;
	governmentInsurance: string;
	masterCardAttentionIcon: Image;
	paymentDetailsList?: DescriptionListProps;
	priceDetailsList: DescriptionListProps;
	userDetailsList: DescriptionListProps;
	travelDetailsList: DescriptionListProps;
}

export const MasterCardReceipt: React.FC<MasterCardReceiptProps> = ({
	className,
	governmentInsurance,
	masterCardAttentionIcon,
	paymentDetailsList,
	priceDetailsList,
	userDetailsList,
	travelDetailsList,
}) => {
	const dictionary = useContext(DictionaryContext);
	const [notifyUserComplete, setNotifyUserComplete] = useState(false);
	useEffect(() => {
		paymentDetailsList != null && setNotifyUserComplete(true);
	}, [paymentDetailsList]);
	{
		return !notifyUserComplete ? (
			<Container width="Medium">
				<PageLoader />
			</Container>
		) : (
			<div className={classNames(styles.MasterCardReceipt, className)}>
				<Container width="Medium">
					{userDetailsList && (
						<DescriptionList className={styles.MasterCardReceipt_list} {...userDetailsList} />
					)}
					{travelDetailsList && (
						<DescriptionList className={styles.MasterCardReceipt_list} {...travelDetailsList} />
					)}
					{priceDetailsList && (
						<DescriptionList className={styles.MasterCardReceipt_list} {...priceDetailsList} />
					)}
				</Container>
				<AttentionCard
					className={styles.MasterCardReceipt_disclaimer}
					conditionsText={
						_MasterCard(dictionary, 'PriceIncludesInsuranceToGovernment') +
						` ${governmentInsurance} ` +
						_Units(dictionary, 'DanishCrowns')
					}
					image={masterCardAttentionIcon}
					cardType={{ type: 'disclaimer' }}
					cardSize={{ size: 'small' }}
				/>

				<Container width="Medium">
					<DescriptionList className={styles.MasterCardReceipt_list} {...paymentDetailsList} />
				</Container>

				<AttentionCard
					className={styles.MasterCardReceipt_info}
					cardType={{ type: 'info' }}
					cardSize={{ size: 'medium' }}
					image={{ url: masterCardCallIcon }}
					conditionsText={_Services(dictionary, 'QuestionsCallCustomerService')}
				/>
			</div>
		);
	}
};
