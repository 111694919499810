import classNames from 'classnames';
import { Logo } from 'components';
import { PageContext } from 'context/PageContext';
import { useContext, useState } from 'react';
import { Grid, GridCell, Navigation, SkipToMain } from 'shared';
import styles from './Header.module.scss';

export interface HeaderProps {
	className?: string;
	navHeading?: string;
	theme?: string;
}

export const Header: React.FC<HeaderProps> = ({ className, navHeading, theme }) => {
	const [navigationIsOpen, setNavigationIsOpen] = useState(false);
	const pageContext = useContext(PageContext);
	const header = pageContext.header;

	return (
		<>
			<header
				className={classNames(
					styles.Header,
					styles[`Header___${theme}`],
					navigationIsOpen && styles.Header___isOpen,
					className,
				)}
				role="banner"
			>
				<SkipToMain link="#main" title="Click to skip navigations and go directly to content section">
					Skip to content
				</SkipToMain>

				<Grid nogutter={true} center={true} className={styles.Header_container}>
					<Logo
						style={navigationIsOpen ? 'Light' : 'Dark'}
						enforceDarkStyleForDesktop
						margined
						logoImageLight={header.logoDarkImage}
						logoImageDark={header.logoImage}
						logoUrl={header.logoUrl?.url}
					/>

					{header.headerLinks ? (
						<div className={classNames(styles.Header_navigation)}>
							<button
								type="button"
								className={classNames(
									styles.Header_navToggle,
									navigationIsOpen && styles.Header_navToggle___isOpen,
								)}
								onClick={() => setNavigationIsOpen(!navigationIsOpen)}
							>
								<span className={styles.Header_icon}>
									<span className={styles.Header_iconBar}></span>
									<span className={styles.Header_iconBar}></span>
									<span className={styles.Header_iconBar}></span>
									<span className={styles.Header_iconBar}></span>
								</span>
							</button>

							<div
								className={classNames(
									styles.Header_nav,
									navigationIsOpen && styles.Header_nav___isOpen,
								)}
							>
								<Grid nogutter={true} wrap={true}>
									<GridCell>
										<Navigation
											heading={navHeading}
											navigationItems={header.headerLinks}
										></Navigation>
									</GridCell>
								</Grid>
							</div>
						</div>
					) : null}

					<div
						className={classNames(
							styles.Header_navBackground,
							navigationIsOpen && styles.Header_navBackground___isOpen,
						)}
					/>
				</Grid>
			</header>
		</>
	);
};
