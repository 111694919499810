import { MasterCardTravelFormData, MasterCardUserFormData, PaymentDetails } from 'components';
import { DescriptionListItemStyle, DescriptionListProps } from 'shared';
import { convertNumber, formatDate } from 'utilities';
import { Dictionary } from '../context/Dictionary';
import { _MasterCard, _Notices, _Policy, _Price, _Status, _Time, _Units, _User } from '../dictionaryItems';

export const mapUserFormData = (
	dictionary: Partial<Dictionary>,
	userFormData: MasterCardUserFormData,
): DescriptionListProps => {
	return {
		heading: _MasterCard(dictionary, 'PolicyOwner'),
		descriptionList: [
			{
				term: _User(dictionary, 'Name'),
				description: userFormData.firstName + ' ' + userFormData.lastName,
			},
			{
				term: _User(dictionary, 'RoadName'),
				description: userFormData.address.roadName + ' ' + userFormData.address.roadNumber,
			},
			{
				term: _User(dictionary, 'PostalCodeAndCiy'),
				description: userFormData.address.postalCode + ' ' + userFormData.address.city,
			},
			{
				term: _User(dictionary, 'Email'),
				description: userFormData.email,
			},
			{
				term: _User(dictionary, 'BirthDate'),
				description: userFormData.birthDate,
			},
		],
	};
};

export const mapTravelFormData = (
	dictionary: Partial<Dictionary>,
	travelFormData: MasterCardTravelFormData,
): DescriptionListProps => {
	return {
		heading: _Policy(dictionary, 'InsuranceInformation'),
		descriptionList: [
			{
				term: _Policy(dictionary, 'NumberOfCoInsured'),
				description: `${travelFormData.insuredCount} ${
					Number(travelFormData.insuredCount) > 1
						? _Units(dictionary, 'Persons')
						: _Units(dictionary, 'Person')
				}`,
			},
			{
				term: _MasterCard(dictionary, 'DepartureDate'),
				description: formatDate(new Date(travelFormData.startDate), 'dd-mm-yyyy'),
			},
			{
				term: _MasterCard(dictionary, 'ReturnDate'),
				description: formatDate(new Date(travelFormData.endDate), 'dd-mm-yyyy'),
			},
		],
	};
};

export const mapPriceSummary = (
	dictionary: Partial<Dictionary>,
	priceSummary: PriceSummaryItem[] | { status: string },
	insuredCount: string,
	masterCardConfig: MasterCardConfig,
	ignoreNumberOfPersons: boolean,
	price?: string,
	priceTravelCompensation?: string,
): DescriptionListProps => {
	if (!priceSummary || ('status' in priceSummary && priceSummary.status == '400')) return null;
	const { publicCancellationCoverage, publicTravelCompensationCoverage, name: cardType } = { ...masterCardConfig };
	const {
		includedMaxSum: maxCancellationIncludedSum,
		maxSum: maxCancellationCoverageSum,
		includedPersonalSum: includedCancellationPersonalSum,
	} = { ...publicCancellationCoverage };

	const {
		includedMaxSum: maxTravelIncludedSum,
		maxSum: maxTravelCoverageSum,
		includedPersonalSum: includedTravelPersonalSum,
	} = { ...publicTravelCompensationCoverage };

	const itemsPrice = priceSummary instanceof Array && priceSummary.map((item) => item.amount);
	const totalPrice = itemsPrice?.reduce((previousAmount, currentAmount) => previousAmount + currentAmount, 0);
	const priceList =
		priceSummary instanceof Array &&
		priceSummary.map((item) => {
			return {
				term:
					item.insurancePriceType === 'extendedCancellation'
						? _MasterCard(dictionary, 'ExtendedCancellationFeeAmount') +
						  ` ${convertNumber(
								calculateCancellationFee(
									Number(price),
									Number(insuredCount),
									maxCancellationIncludedSum,
									maxCancellationCoverageSum,
									includedCancellationPersonalSum,
									ignoreNumberOfPersons,
									cardType,
								),
						  )} ` +
						  _Units(dictionary, 'DanishCrowns')
						: item.insurancePriceType === 'travelCompensation'
						? _MasterCard(dictionary, 'ExtendedTravelCompensationFeeAmount') +
						  ` ${convertNumber(
								calculateCancellationFee(
									Number(priceTravelCompensation),
									Number(insuredCount),
									maxTravelIncludedSum,
									maxTravelCoverageSum,
									includedTravelPersonalSum,
									ignoreNumberOfPersons,
									cardType,
								),
						  )} ` +
						  _Units(dictionary, 'DanishCrowns')
						: item.description,
				description: item.amount ? convertNumber(item.amount) : _Notices(dictionary, 'NotRequired'),
				style: item.amount ? ('large' as DescriptionListItemStyle) : null,
				decorator: item.amount > 0 && _Units(dictionary, 'DanishCrowns'),
			};
		});
	return {
		heading: _MasterCard(dictionary, 'YourPrice'),
		descriptionList: priceList.concat({
			term: _Price(dictionary, 'Total'),
			description: convertNumber(totalPrice),
			style: 'highlight' as DescriptionListItemStyle,
			decorator: _Units(dictionary, 'DanishCrowns'),
		}),
	};
};

export const mapPaymentDetails = (
	dictionary: Partial<Dictionary>,
	paymentDetails: PaymentDetails,
): DescriptionListProps => {
	return {
		heading: _MasterCard(dictionary, 'PaymentDetails'),
		descriptionList: [
			{
				term: _MasterCard(dictionary, 'CardType'),
				description: paymentDetails.cardName,
			},
			{
				term: _MasterCard(dictionary, 'DibsTransactionNumber'),
				description: paymentDetails.id,
			},
			{
				term: _Time(dictionary, 'Date'),
				description: formatDate(paymentDetails.date, 'dd.mm.yyyy'),
			},
			{
				term: _Price(dictionary, 'Amount'),
				description: String(convertNumber(paymentDetails.amount)),
				decorator: _Units(dictionary, 'DanishCrowns'),
			},
			{
				term: _Status(dictionary, 'Status'),
				description: _Status(dictionary, 'Completed'),
			},
		],
	};
};

const calculateCancellationFee = (
	price: number,
	insuredCount: number,
	maxIncludedSum: number,
	maxCancellationCoverage: number,
	includedPersonalSum: number,
	ignoreNumberOfPersons: boolean,
	cardType: string,
): number => {
	const personalSum = ignoreNumberOfPersons ? includedPersonalSum : insuredCount * includedPersonalSum;
	const includedSum = personalSum > maxIncludedSum ? maxIncludedSum : personalSum;

	const cancellationFee = price - includedSum;
	if (cancellationFee <= 0) return 0;
	if (cardType === 'WEMN') {
		return cancellationFee;
	} else {
		return cancellationFee > maxCancellationCoverage ? maxCancellationCoverage : cancellationFee;
	}
};
