import { Dictionary } from 'Online/context/Dictionary';

export type PolicyDictionaries =
	| 'MissingPolicies'
	| 'GetPolicyOffer'
	| 'InsuranceInformation'
	| 'NumberOfCoInsured'
	| 'NumberOfInsured'
	| 'DownloadPolicy';

export const _Policy = (dictionary: Partial<Dictionary>, item: PolicyDictionaries): string => {
	switch (item) {
		case 'MissingPolicies':
			return dictionary.getValue('Policy.MissingPolicies', null, 'Du har ingen forsikringer.');

		case 'GetPolicyOffer':
			return dictionary.getValue('Policy.GetPolicyOffer', null, 'Indhent forsikringstilbud');

		case 'InsuranceInformation':
			return dictionary.getValue('Policy.InsuranceInformation', null, 'Forsikringsoplysninger');

		case 'NumberOfCoInsured':
			return dictionary.getValue('Policy.NumberOfCoInsured', null, 'Antal medforsikrede');

		case 'NumberOfInsured':
			return dictionary.getValue('Policy.NumberOfInsured', null, 'Antal forsikrede');

		case 'DownloadPolicy':
			return dictionary.getValue('Policy.DownloadPolicy', null, 'Hent forsikringspolice');
	}
};
