import classNames from 'classnames';
import { useContext } from 'react';
import { Container, Factbox, FactboxItemProps, FormfieldToggle, Heading, RichText } from 'shared';
import { DictionaryContext } from '../../../../../context/Dictionary';
import { _MasterCard } from '../../../../../dictionaryItems';
import styles from './MasterCardConditions.module.scss';

export interface MasterCardConditionsProps {
	conditionsAccepted: (accepted: boolean) => void;
	heading: string;
	text: string;
	paymentProviders: FactboxItemProps[];
}

export const MasterCardConditions: React.FC<MasterCardConditionsProps> = ({
	conditionsAccepted,
	heading,
	text,
	paymentProviders,
}) => {
	const dictionary = useContext(DictionaryContext);
	return (
		<div className={classNames(styles.MasterCardConditions)}>
			<Container width="Medium">
				<Heading className={styles.MasterCardConditions_heading} headingLevel="h3">
					{heading}
				</Heading>
				<RichText className={styles.MasterCardConditions_text} content={text} />
				<FormfieldToggle
					className={styles.MasterCardConditions_toggle}
					label={{ active: _MasterCard(dictionary, 'IAcceptTheConditions') }}
					name="conditions-accepted"
					id="toggle-conditions"
					callback={(checked) => conditionsAccepted(checked)}
				/>
				<Factbox factboxItems={paymentProviders} style="vanilla" />
			</Container>
		</div>
	);
};
