import classNames from 'classnames';
import { ErrorMessage, FormfieldHelpCta, Label } from 'shared';
import styles from './FormfieldSelect.module.scss';

type Option = {
	value: string;
	text: string;
	disabled?: boolean;
	hidden?: boolean;
	selected?: boolean;
};

type State = {
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	isHidden?: boolean;
};

export interface FormfieldSelectProps {
	className?: string;
	closeCtaText?: string;
	id: string;
	helpCtaText?: string;
	multiple?: boolean;
	name?: string;
	label: string;
	options: Option[];
	size?: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	register?: any;
	errorMessage?: string;
	state?: State;
	defaultValue?: string;
}

const ariaLabel = (state: State, label: string) => {
	if (state?.['isHidden']) return label;
};

const ariaError = (state: State, name: string, id: string) => {
	if (state?.['hasError']) return name + id;
};

export const FormfieldSelect: React.FC<FormfieldSelectProps> = ({
	className,
	closeCtaText,
	register,
	errorMessage,
	state,
	id,
	helpCtaText,
	label,
	options,
	name,
	multiple,
	size,
	defaultValue,
}) => {
	const { required, disabled, hasError } = state ?? {};

	return (
		<div
			className={classNames(
				styles.FormfieldSelect,
				className,
				{ [styles.hasError]: hasError },
				{ [styles.isDisabled]: disabled },
			)}
		>
			<div className={styles.FormfieldSelect_label}>
				<Label id={id} className={styles.FormfieldSelect_labelText} state={state}>
					{label}
				</Label>
				{helpCtaText && <FormfieldHelpCta closeText={closeCtaText} helpText={helpCtaText} />}
			</div>

			<div
				className={classNames(styles.FormfieldSelect_wrapper, {
					[styles.isMultiple]: multiple === true,
				})}
			>
				<select
					name={name}
					id={id}
					className={classNames(styles.FormfieldSelect_element)}
					aria-label={ariaLabel(state, label)}
					aria-describedby={ariaError(state, name, id)}
					multiple={multiple}
					size={size}
					disabled={disabled}
					defaultValue={defaultValue}
					{...(register && { ...register(name, { required }) })}
				>
					{options.map((option, index) => (
						<option value={option.value} disabled={option.disabled} hidden={option.hidden} key={index}>
							{option.text}
						</option>
					))}
				</select>
			</div>
			{hasError && <ErrorMessage id={name + id}>{errorMessage}</ErrorMessage>}
		</div>
	);
};
