import classNames from 'classnames';
import {
	Layout,
	Main,
	MasterCardHeader,
	MasterCardReceipt,
	MasterCardSummary,
	MasterCardTravel,
	MasterCardTravelFormData,
	MasterCardUserForm,
	MasterCardUserFormData,
	PaymentDetails,
} from 'components';
import { PageContext } from 'context/PageContext';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AttentionCard, Container, Heading, PageLoader, Picture, StepIndicator } from 'shared';
import { DictionaryContext } from '../../../context/Dictionary';
import { _Errors, _MasterCard } from '../../../dictionaryItems';
import { convertNumber } from '../../../utilities';
import { getPartnerByCardType } from '../../../utilities/getPartnerByCadType';
import {
	mapPaymentDetails,
	mapPriceSummary,
	mapTravelFormData,
	mapUserFormData,
} from '../../../utilities/mastercardHelpers';
import { toKebabCase } from '../../../utilities/string';
import styles from './MasterCardPage.module.scss';
import { MasterCardListType } from './MasterCardPageAPI';

export interface MasterCardPageProps extends PageModel {
	callbackEmail: () => void;
	callbackPayment: () => void;
	currentStep: number;
	errorIcon?: Image;
	formSubmit: (formData: MasterCardTravelFormData | MasterCardUserFormData, type: MasterCardListType) => void;
	masterCardConfig: MasterCardConfig;
	paymentError: boolean;
	paymentDetails: PaymentDetails;
	paymentId: string;
	cardType: MasterCardType;
	cardTypeGroup: MasterCardTypeGroup;
	priceSummary: PriceSummary;
	travelFormData: MasterCardTravelFormData;
	userFormData: MasterCardUserFormData;
	setCurrentStep: Dispatch<SetStateAction<number>>;
	status: {
		configStatus?: Status;
		calculationStatus?: Status;
	};
}

export const MasterCardPage: React.FC<MasterCardPageProps> = (props) => {
	const {
		callbackEmail,
		callbackPayment,
		cardType,
		cardTypeGroup,
		currentStep,
		conditionsHeadline,
		errorIcon,
		formSubmit,
		userFormData,
		travelFormData,
		paymentError,
		paymentDetails,
		priceSummary,
		paymentProviders,
		setCurrentStep,
		status,
	} = props ?? {};

	const pageContext = useContext(PageContext);
	const { theme } = pageContext;

	let [searchParams, setSearchParams] = useSearchParams();
	let navigate = useNavigate();

	const dictionary = useContext(DictionaryContext);
	// const router = useRouter();
	// const basePath = router?.asPath.split('?')[0];
	const [steps] = useState([
		_MasterCard(dictionary, 'YourJourney'),
		_MasterCard(dictionary, 'AboutYou'),
		_MasterCard(dictionary, 'Overview'),
		_MasterCard(dictionary, 'Payment'),
		_MasterCard(dictionary, 'Receipt'),
	]);
	const [governmentInsurance, setGovernmentInsurance] = useState<string>(null);
	const [priceDetailsList, setPriceDetailsList] = useState(null);
	const [userNotified, setUserNotified] = useState(false);
	const [paymentInitiated, setPaymentInitiated] = useState(false);
	const partner = getPartnerByCardType(cardType);

	const handleTravelForm = (travelFormData: MasterCardTravelFormData) => {
		formSubmit(travelFormData, 'travel');
		handleRoute(currentStep + 1);
	};
	const handleUserForm = (userFormData: MasterCardUserFormData) => {
		formSubmit(userFormData, 'user');
		handleRoute(currentStep + 1);
	};
	const handlePayment = () => {
		callbackPayment();
		setPaymentInitiated(true);
		handleRoute(currentStep + 1);
	};

	const handleRoute = (goToStep: number) => {
		goToStep !== 1
			? navigate(`${props.termsUrl?.url}?s=${toKebabCase(steps[goToStep - 1])}`)
			: navigate(`${props.termsUrl?.url}`);
	};

	useEffect(() => {
		const urlPaymentId = searchParams.get('paymentid');

		if (props.paymentId === urlPaymentId && urlPaymentId != undefined) {
			userNotified && navigate(`${props.termsUrl?.url}?s=${toKebabCase(steps[steps.length - 1])}`);
			if (!userNotified) {
				callbackEmail();
				setUserNotified(true);
			}
		}
	}, [searchParams.get('paymentid'), steps, navigate, props.termsUrl?.url, userNotified, props.paymentId]);

	useEffect(() => {
		window.scrollTo(0, 0);
		const urlCurrentStep = searchParams.get('s'); //router?.query?.s;

		if (urlCurrentStep) {
			const urlCurrentStepIndex = steps.findIndex((step) => toKebabCase(step) === urlCurrentStep);
			if (urlCurrentStepIndex == 3 && !paymentInitiated) {
				setCurrentStep(urlCurrentStepIndex);
			} else {
				setCurrentStep(urlCurrentStepIndex + 1);
				setPaymentInitiated(true);
			}
		} else {
			setCurrentStep(paymentDetails ? 5 : 1);
		}
	}, [searchParams, steps, setCurrentStep, paymentDetails, paymentInitiated]);

	useEffect(() => {
		const ignoreNumberOfPersons = cardType === 'WEM' || cardType === 'MP' || cardType === 'MPB';

		setPriceDetailsList(
			mapPriceSummary(
				dictionary,
				priceSummary?.priceDetails,
				travelFormData?.insuredCount,
				props?.masterCardConfig,
				ignoreNumberOfPersons,
				travelFormData?.price,
				travelFormData?.priceTravelCompensation,
			),
		);
	}, [dictionary, travelFormData, priceSummary, props?.masterCardConfig, cardTypeGroup, status]);

	useEffect(() => {
		const insurance = priceSummary?.insuranceFee?.amount ? convertNumber(priceSummary.insuranceFee.amount) : '0';
		setGovernmentInsurance(insurance);
	}, [priceSummary?.insuranceFee?.amount]);

	const confirmationText = `<h2>
		${_MasterCard(dictionary, 'TravelPolicyPurchaseAcknowledgement')}
		</h2>
		<p>
		${_MasterCard(dictionary, 'YourOrderNumberIs')}
		 ${props?.paymentId}
		 </p> <br>
		 <p>
		${_MasterCard(dictionary, 'ConfirmationPayment')}
		</p>
		${paymentDetails?.mailDidSend ? `<p>` + _MasterCard(dictionary, 'ConfirmationEmail') + `</p>` : ''}`;

	return (
		<Layout {...props}>
			<Main>
				<div
					className={classNames(
						styles.MasterCardPage,
						props?.bannerImage?.url && styles.MasterCardPage___withBannerImage,
					)}
				>
					{Object.keys(props.bannerImage).length > 0 && (
						<div className={styles.MasterCardPage_bannerImage}>
							<Picture url={props.bannerImage.url} isCover />
							{theme === 'Dark' && (
								<Heading className={styles.MasterCardPage_title} headingLevel="h1">
									{props.headline}
								</Heading>
							)}
						</div>
					)}
					<StepIndicator
						className={styles.MasterCardPage_stepindicator}
						heading={
							theme === 'Dark'
								? _MasterCard(dictionary, 'AddonBuyForYourTravelInsurance')
								: props.headline
						}
						headingLevel={theme === 'Dark' ? 'h2' : 'h1'}
						currentStepIndex={currentStep - 1}
						steps={steps}
					/>

					{currentStep === 5 && paymentDetails && (
						<div className={styles.MasterCardPage_attentionCards}>
							<Container width="Medium">
								<AttentionCard
									className={styles.MasterCardPage_confirmationCard}
									image={null}
									conditionsText={confirmationText}
									cardType={{ type: 'success' }}
									cardSize={{ size: 'medium' }}
								/>
								{!paymentDetails?.mailDidSend && (
									<AttentionCard
										className={styles.MasterCardPage_confirmationCard}
										image={errorIcon}
										conditionsText={`<p>` + _Errors(dictionary, 'EmailConfirmationError') + `</p>`}
										cardType={{ type: 'error' }}
										cardSize={{ size: 'medium' }}
									/>
								)}
							</Container>
						</div>
					)}
					<MasterCardHeader
						className={styles.MasterCardPage_header}
						description={
							currentStep === 1 &&
							_MasterCard(
								dictionary,
								cardTypeGroup === 'standard'
									? `DescriptionNonPremium${partner}`
									: cardTypeGroup == 'platinum' || cardTypeGroup == 'gold'
									? 'DescriptionPremium'
									: cardTypeGroup === 'worldelite'
									? `DescriptionWorldElite${partner}`
									: null,
							)
						}
						heading={steps[currentStep - 1]}
						masterCardImage={props.image}
					/>

					<div className={styles.MasterCardPage_content}>
						{currentStep === 1 ? (
							<MasterCardTravel
								callbackContinue={(data) => handleTravelForm(data)}
								callbackTravelForm={(data) => formSubmit(data, 'travel')}
								cardType={cardType}
								cardTypeGroup={cardTypeGroup}
								errorIcon={errorIcon}
								priceDetailsList={priceDetailsList}
								masterCardAttentionIcon={props?.masterCardAttentionIcon}
								maxMonthsCovered={props?.masterCardConfig?.maxMonthsCovered}
								maxNumberOfInsured={props?.masterCardConfig?.maxNumberOfInsured}
								hasExtendedPeriodOption={props?.masterCardConfig?.hasExtendedPeriodOption}
								travelFormData={travelFormData}
								governmentInsurance={governmentInsurance}
								status={status.calculationStatus}
							/>
						) : currentStep === 2 ? (
							<MasterCardUserForm
								callbackPrevious={() => handleRoute(currentStep - 1)}
								callbackContinue={(data) => handleUserForm(data)}
								insuredCount={Number(travelFormData.insuredCount)}
								userFormData={userFormData}
								cardTypeGroup={cardTypeGroup}
							/>
						) : currentStep === 3 ? (
							<MasterCardSummary
								conditionsHeading={conditionsHeadline}
								conditionsText={props?.conditionsText}
								masterCardAttentionIcon={props?.masterCardAttentionIcon}
								paymentProviders={paymentProviders.items}
								userDetailsList={mapUserFormData(dictionary, userFormData)}
								travelDetailsList={mapTravelFormData(dictionary, travelFormData)}
								callbackPrevious={() => handleRoute(currentStep - 1)}
								callbackContinue={handlePayment}
								priceDetailsList={priceDetailsList}
								governmentInsurance={governmentInsurance}
							/>
						) : currentStep === 4 ? (
							<Container width="Medium">
								{paymentError ? (
									<AttentionCard
										className={styles.MasterCardTravel_errorCard}
										image={errorIcon}
										conditionsText={`<p>` + _Errors(dictionary, 'FetchError') + `</p>`}
										cardType={{ type: 'error' }}
										cardSize={{ size: 'large' }}
									/>
								) : (
									<PageLoader maxHeight="41rem" />
								)}
							</Container>
						) : currentStep === 5 ? (
							<MasterCardReceipt
								masterCardAttentionIcon={props?.masterCardAttentionIcon}
								userDetailsList={mapUserFormData(dictionary, userFormData)}
								travelDetailsList={mapTravelFormData(dictionary, travelFormData)}
								paymentDetailsList={paymentDetails && mapPaymentDetails(dictionary, paymentDetails)}
								priceDetailsList={priceDetailsList}
								governmentInsurance={governmentInsurance}
							/>
						) : null}
					</div>
				</div>
			</Main>
			{/* {pageContext?.footer && <Footer components={pageContext.footer} />} */}
		</Layout>
	);
};
